.movie-show {
    color: white;
}

.movie-show__backdrop {
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    &::after {
        content: '';
        background-color: rgba(0, 0, 0, 0.9);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
}

.movie-show__heading {
    color: $white-red;
    font-size: $font-size-large;
    margin-bottom: $m-size;
    text-transform: uppercase;
}

.movie-show__heading--top {
    margin-top: $xl-size;
}

.movie-show__main-container {
    position: relative;
}

.movie-show__main {
    display: grid;
    grid-template-columns: 1fr;
    
    @media (min-width: $desktop-breakpoint) {
        grid-template-columns: 0.5fr 2fr;
        padding: $l-size 0;
        position: relative;
        z-index: 3;
    }
}

.movie-show__image {
    border-radius: 3px;
    justify-self: center;
    margin-top: $xl-size;
    width: 250px;
    z-index: 2;

    @media (min-width: $desktop-breakpoint) {
        margin: 0;
        justify-self: normal;
        width: 300px;
    }
}

.moive-show__description {
    margin-bottom: $xl-size;
    z-index: 2;
    
    @media (min-width: $desktop-breakpoint) {
        margin-left: $xl-size;
    }
}

.movie-show__title {
    font-size: $font-size-xlarge;
    margin-bottom: $s-size;

    spam {
        font-size: $font-size-medium;
        opacity: 0.6;
        display: block;
    }
}

.movie-show__genres {
    font-size: $font-size-small;
    font-weight: 300;
    font-style: italic;
}

.movie-show__subcontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    & > * {
        margin: $s-size 0;
    }
}

.movie-show__links {
    text-align: center;
    
    & > * {
        color: $light-red;
        margin: $m-size $s-size;
        text-decoration: none;

        &:hover {
            color: $medium-red;
        }
    }
}

.cast-container {
    display: grid;
    grid-gap: $s-size;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: $desktop-breakpoint) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.recommendation-container {
    display: grid;
    grid-gap: $s-size;
    grid-template-columns: repeat(2, 1fr);
    
    @media (min-width: $desktop-breakpoint) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.movie-show__prod {
    color: $white-red;
    text-transform: uppercase;
}

.movie-show__last-season {
    background: rgba($dark-red, .7);
    display: grid;
    grid-template-columns: 1fr;
    padding: $m-size;
    
    @media (min-width: $desktop-breakpoint) {
        grid-template-columns: 0.5fr 2fr;
    }
}

.last-season__image {
    width: 154px;
    justify-self: center;
    margin-bottom: $s-size;
    
    @media (min-width: $desktop-breakpoint) {
        justify-self: normal;
        margin-bottom: 0;
    }
}

.last-season__content {
    * {
        margin: 0;
        margin-left: $s-size;
        margin-bottom: 0.7rem;
    }

    @media (min-width: $large-desktop-breakpoint) {
        * {
            margin-bottom: $m-size;
        }
    }
}