*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    background-color: $background;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
}

button {
    cursor: pointer;
}

#page-content {
    min-height: 100vh;
    padding-bottom: 200px;
    position: relative;
}