// Colors
$background: #202523;
$dark-red: #3F0D12;
$medium-red: #66101F;
$light-red: #821C2D;
$white-red: #CE4865;
$off-white: lighten($white-red, 30%);

// Font Size
$font-size-xlarge: 3rem;
$font-size-large: 2.2rem;
$font-size-medium: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

// Breakpoints
$desktop-breakpoint: 45rem;
$large-desktop-breakpoint: 52rem;

// Mixins
@mixin wrap-text($line-n) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $line-n;
    -webkit-box-orient: vertical;
}