.movie-show-card {
    background: rgba(darken(#202523, 10%), 0.8);
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    height: 228px;
    margin-bottom: $m-size;

    @media (min-width: $desktop-breakpoint) {
        grid-template-columns: 1fr 1fr;
        height: 100%;
        margin-bottom: 0;
    }
}

.movie-show-card__image {
    cursor: pointer;
    height: 228px;
    position: relative;
    width: 154px;

    a {
        height: 100%;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }


    @media (min-width: $desktop-breakpoint) {
        height: 100%;
        width: 100%;

        &::after {
            background: rgba(0, 0, 0, 0.8);
            content: '';
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            transition: 0.3s all ease;
            top: 0;
            width: 0;
        }

        &:hover::after {
            width: 100%;
        }
    }
}

.movie-show-card__description {
    padding: $m-size;
}

.description__subcontainer {
    text-align: center;

    @media (min-width: $desktop-breakpoint) {
        display: flex;
        justify-content: space-between;
    }
}

.description__title {
    margin-top: 0;

    a {
        @include wrap-text(2);
        color: $light-red;
        text-decoration: none;

        &:hover {
            color: darken($light-red, 5%);
        }
    }

    @media (min-width: $desktop-breakpoint) {
        margin-top: $m-size;
    }
}

.description__average {
    color: $white-red;
    font-size: $font-size-small;
    margin-top: 0;
    margin-bottom: 0.8rem;

    @media (min-width: $desktop-breakpoint) {
        margin-top: $m-size;
    }
}

.description__date {
    color: $white-red;
    font-size: $font-size-small;
    margin-top: 0;
    margin-bottom: 0.8rem;

    @media (min-width: $desktop-breakpoint) {
        margin-top: $m-size;
    }
}

.description__overview {
    @include wrap-text(3);
    color: $off-white;
    font-size: $font-size-small;
    margin-top: 0;

    @media (min-width: $desktop-breakpoint) {
        margin-top: $m-size;
        -webkit-line-clamp: 6;
    }
}

.description__info {
    text-align: center;
    margin-top: $l-size;

    a {
        background: $dark-red;
        color: $off-white;
        padding: 0.5rem;
        text-decoration: none;

        &:hover {
            background: darken($dark-red, 5%);
        }
    }
}