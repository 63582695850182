.footer {
    background: rgba($dark-red, 0.7);
    margin-top: $xl-size;
    padding: 5rem 0;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footer__scroll-top {
    &:hover {
        color: $white-red;
        cursor: pointer;
    }
}