.paginator {
    display: grid;
    justify-self: center;
    margin-top: $l-size;
    justify-items: center;
}

.paginator ul {
    background: $medium-red;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.paginator li {
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: 0.6rem;

    a {
        outline: none;
    }

    @media (min-width: $desktop-breakpoint) {
        padding: $s-size;
    }
}

.active-page {
    a {
        color: $dark-red;
        font-weight: 800;
        padding: 0.5rem;
    }
}

.previous {
    border-right: 1px solid $white-red;
    color: $white-red;
    font-weight: 800;
    margin-right: $m-size;
    padding-right: $m-size;
}

.next {
    border-left: 1px solid $white-red;
    color: $white-red;
    font-weight: 800;
    margin-left: $m-size;
    padding-left: $m-size;
}

.disabled-arrow {
    color: $dark-red;
}

.page {
    color: $off-white;
}